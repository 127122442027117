<template>
  <v-container>
    <v-text-field
      v-if="!loading"
      v-model="search"
      prepend-inner-icon="ph-magnifying-glass"
      placeholder="Pesquisar pelo nome do plano"
      hide-details
      dense
      color="accent"
      filled
      rounded
      full-width
      clearable
      class="ma-4"
    />

    <!-- TODO: quando tiver os serviços junto, um filtro -->
    <!-- <v-tabs v-if="isConecta" v-model="filter" class="mx-4">
      <v-tab v-for="(tabFilter, i) in filterOptions" :key="i">
        {{ tabFilter.name }}
      </v-tab>
    </v-tabs> -->

    <v-item-group :show="show" class="pa-0">
      <v-row
        v-if="allPlans.length > 0"
        align-content="center"
        justify="start"
        justify-xs="center"
        :class="`pt-4 px-0 ma-0`"
      >
        <v-col
          v-for="(plan, i) in allPlans"
          :key="i"
          xs="1"
          sm="6"
          lg="4"
          xl="4"
          class="d-flex justify-start px-0"
        >
          <v-item v-slot="{ active }" :value="plan">
            <CardReseller
              :data-testid="`plan-card-${plan.name}`.replace(/ /g, '')"
              :logo="plan.short_code"
              :title="plan.name"
              :plan-key="plan.key"
              :plan="plan"
              :products="plan.products"
              :featured="plan.sku === FEATURED_SKU || plan.featured"
              :bestOffer="plan.best_offer"
              :class="`mx-5 ${
                active && !clientHasThisPlan
                  ? 'selected-plan-or-service-border'
                  : ''
              }`"
              is-new-plan
              :is-selected="active"
              @selected="$emit('selected', plan.key)"
            />
          </v-item>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="grey--text text--darken-2 font-italic mt-4 px-8 py-5 d-flex justify-center"
      >
        {{
          search
            ? "Nenhum resultado para o termo inserido."
            : "Não há planos disponíveis para adquirir no momento."
        }}
      </v-row>
    </v-item-group>
  </v-container>
</template>
<script>
import CardReseller from "@/components/reseller/cards/CardReseller";

import { FEATURED_SKU } from "@/helpers/variables/modules";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PlanSelector",

  props: {
    show: { type: Boolean, default: true },
    hideServices: { type: Boolean, default: true },
  },

  components: { CardReseller },

  data() {
    return {
      FEATURED_SKU,
      search: "",
      filter: "plans",
      filterOptions: [
        {
          name: "Planos",
          value: "plans",
        },
        {
          name: "Serviços",
          value: "services",
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      "isSelfCheckout",
      "plans",
      "services",
      "loading",
      "clientHasThisPlan",
      "hasPlans",
      "clientActivePlans",
      "isConecta",
      "planInCart",
      "clientHasThisPlan",
      "clientPlans",
      "isConecta",
    ]),

    allPlans() {
      const available_plans = this.isConecta
        ? this.plans
        : this.plans.filter((plan) => {
            return !plan.only_conecta_hires;
          });

      return available_plans
        .filter((plan) =>
          this.search
            ? plan.name.toLowerCase().includes(this.search.toLowerCase()) &&
              plan.is_active
            : plan.is_active
        )
        .sort((a, b) => {
          if (a.short_code === "conecta_suite") {
            return -1;
          }
          if (b.short_code === "conecta_suite") {
            return 1;
          }
          if (a.is_basic) {
            return -1;
          }
          if (b.is_basic) {
            return 1;
          }
          if (a.price < b.price) {
            return -1;
          }
          if (a.price > b.price) {
            return 1;
          }
          return 0;
        });
    },
  },

  methods: {
    ...mapMutations(["setSelectedPlan"]),
  },
};
</script>
