<template>
  <v-col v-if="!services.length" class="d-flex justify-center mt-4">
    <span>
      Nenhum serviço disponível, acesse
      <span class="goto" @click="goToPlanManagement">
        Gerenciar planos e serviços
      </span>
      para criar um novo serviço.
    </span>
  </v-col>

  <div v-else>
    <v-scroll-y-transition>
      <ServiceSelector
        v-if="showServices"
        class="mx-auto pb-0 elevation-0 overflow-y-auto"
        :loading="loading"
        :disabled="loading"
        @add="addService"
      />
    </v-scroll-y-transition>

    <v-card tile flat>
      <v-card-title class="px-5 mb-1 mt-4" v-if="isServicePage">
        Serviços selecionados ({{ serviceShoppingCart.length }}
        {{ serviceShoppingCart.length === 1 ? "serviço" : "serviços" }})
      </v-card-title>

      <v-card-text class="mt-0 pt-0" v-if="serviceShoppingCart.length > 0">
        <Alert
          v-if="!allValidDiscounts"
          class="mx-0 mt-0 mb-4 pt-4"
          :prominent="false"
          color="error"
        >
          <p class="mb-0 pb-0">
            Por favor, insira uma porcentagem válida de 0% a 100%
          </p>
        </Alert>
        <v-data-table
          style="border: 1px solid rgba(0, 0, 0, 0.12)"
          :headers="headers"
          :items="serviceShoppingCart"
          hide-default-footer
          disable-sort
          mobile-breakpoint="710"
          no-data-text="Nenhum serviço adicionado"
          class="elevation-0 mt-6 pb-0"
        >
          <!-- COLUNA DE NOME -->
          <template v-slot:[`item.name`]="{ item }"> {{ item.name }} </template>

          <!-- COLUNA DE PREÇO -->
          <template v-slot:[`item.price`]="{ item }">
            {{ formatMoney(item.price) }}
          </template>

          <!-- COLUNA DE DESCONTO -->
          <template v-slot:[`item.discount`]="{ item }">
            <v-text-field
              style="max-height: 45px; max-width: 120px"
              :disabled="item.price === 0"
              v-model="item.discount"
              width="120"
              outlined
              suffix="%"
              @change="validateDiscount(item)"
              dense
              type="number"
              class="pl-0"
            />
          </template>

          <!-- COLUNA DE PARCELAS -->
          <template v-slot:[`item.installments`]="{ item }">
            <v-select
              style="max-height: 45px; max-width: 120px"
              dense
              :items="periods"
              :disabled="item.price === 0"
              v-model="item.installments"
              @change="
                editServiceInstallment({
                  service_key: item.key,
                  value: item.installments,
                })
              "
              item-text="name"
              outlined
              class="pl-0"
            >
              <template v-slot:append>
                <v-icon v-text="'ph-fill ph-caret-down'" />
              </template>
            </v-select>
          </template>

          <!-- TOTAL PRICE -->
          <template v-slot:[`item.totalPrice`]="{ item }">
            <p v-if="item.installments == 1" class="pl-0 my-2">
              {{ formatMoney(item.price - (item.discount / 100) * item.price) }}
            </p>
            <p v-else class="pl-0 my-2">
              {{ item.installments }}x de
              {{
                formatMoney(
                  (item.price - (item.discount / 100) * item.price) /
                    item.installments
                )
              }}
              ({{
                formatMoney(item.price - (item.discount / 100) * item.price)
              }})
            </p>
          </template>

          <!-- REMOVER SERVIÇO -->
          <template v-slot:[`item.key`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  color="error"
                  v-on="on"
                  @click="removeService(item)"
                >
                  <v-icon v-text="'ph-trash'" />
                </v-btn>
              </template>
              <span>Remover serviço</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions v-if="hasAction" class="justify-end pr-4 pb-6">
        <v-btn
          large
          color="accent"
          :loading="loading"
          :disabled="!serviceShoppingCart.length || loading"
          class="mt-6 font-weight-medium text-none text-body-2 px-5"
          elevation="0"
          @click="buyServices"
        >
          Confirmar compra
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import ServiceSelector from "@/components/purchase/ServiceSelector.vue";
import { formatMoney } from "@/helpers/services/utils";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Service",
  components: { ServiceSelector },

  props: {
    loading: { type: Boolean, default: false },
    hasAction: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    showServices: { type: Boolean, default: true },
  },

  data: () => ({
    serviceShoppingCart: [],

    moneyOptions: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      precision: 2,
    },
    periods: [
      { name: "1x", id: 0, value: 1 },
      { name: "2x", id: 1, value: 2 },
      { name: "3x", id: 2, value: 3 },
      { name: "4x", id: 3, value: 4 },
      { name: "5x", id: 4, value: 5 },
    ],
    defaultPeriod: { name: "x1", id: 0, value: 1 },
  }),

  computed: {
    ...mapGetters(["services", "contentWidth", "token", "clientServices"]),

    isServicePage() {
      return this.$route.name === "ResellerNewService";
    },

    headers() {
      return [
        { text: "Serviço", value: "name" },
        { text: "Valor", value: "price", align: "end" },
        { text: "Desconto (%)", value: "discount" },
        { text: "Parcelas", value: "installments" },
        { text: "Preço total (R$)", value: "totalPrice", align: "end" },
        { text: "", value: "key", width: 60 },
      ];
    },

    serviceKeyInCart() {
      return this.serviceShoppingCart.map((service) => service.key);
    },

    allValidDiscounts() {
      if (this.serviceShoppingCart.length) {
        return this.serviceShoppingCart.some(
          ({ discount }) => discount >= 0 && discount <= 100
        );
      } else {
        return true;
      }
    },
  },

  watch: {
    servicesCart(newValue) {
      this.$emit("cart", newValue);
    },
  },

  methods: {
    ...mapMutations([
      "addServiceToCart",
      "removeServiceFromCart",
      "editServiceInstallment",
      "setServiceDiscount",
    ]),

    formatMoney,

    addService(service) {
      const installments = 1;
      const { price, discount, name, key } = service;
      this.serviceShoppingCart.push({
        price,
        discount,
        name,
        key,
        installments,
      });
      this.addServiceToCart(service);
    },

    removeService(service) {
      const { key } = service;
      const index = this.serviceShoppingCart.findIndex((service_in_cart) => {
        return service_in_cart.key === key;
      });

      if (index != -1) {
        this.serviceShoppingCart.splice(index, 1);
      }
      this.removeServiceFromCart(service);
    },

    buyServices() {
      const payload = { services: this.serviceShoppingCart };
      this.$emit("action", payload);
      this.serviceShoppingCart = [];
    },

    goToPlanManagement() {
      this.$router.push({
        path: "/plan-management",
      });
    },

    validateDiscount(item) {
      for (let index = 0; index < this.serviceShoppingCart.length; index++) {
        if (this.serviceShoppingCart[index].key == item.key) {
          let discount = this.serviceShoppingCart[index].discount;

          this.setServiceDiscount({
            service_key: item.key,
            value: parseFloat(item.discount),
          });

          if (!discount || discount < 0 || discount > 100) {
            this.serviceShoppingCart[index].discount = 0;
          }
        }
      }
    },
  },
};
</script>
<style>
.goto {
  cursor: pointer !important;
  color: #329ef4;
  font-weight: bold;
}
</style>
