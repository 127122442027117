<template>
  <HeaderDialog
    :show="showDialog"
    :title="title"
    width="600"
    :color="primaryColor"
    action-text="Confirmar"
    close-text="Cancelar"
    button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
    :disabledAction="!challengeAnswer"
    @close="showDialog = false"
    @action="validate"
  >
    <template v-slot:body>
      <p class="mb-8" v-if="description" v-html="description" />

      <v-card
        outlined
        tile
        height="100"
        class="problem d-flex justify-center align-center text-h3 font-weight-medium"
      >
        {{ mathProblem }}
      </v-card>
      <v-btn
        v-if="!canNotReload"
        color="grey darken-1"
        block
        small
        text
        outlined
        tile
        @click="getChallenge"
        class="mb-6 font-weight-bold"
      >
        <v-icon v-text="'ph-arrow-clockwise'" left /> Atualizar problema
      </v-btn>

      <v-scroll-y-transition>
        <Alert
          v-if="challengeAnswer && hasError"
          color="error"
          class="mb-8 font-weight-medium"
          dark
          dense
          :text="false"
        >
          O valor inserido não corresponde com a resposta esperada.
        </Alert>
      </v-scroll-y-transition>

      <v-form style="width: 100%" ref="mathChallenge">
        <v-text-field
          v-model="challengeAnswer"
          label="Resultado do problema"
          type="number"
          outlined
          clearable
          hide-spin-buttons
          :error="hasError"
          class="challenge-button"
          @change="hasError = false"
        ></v-text-field>
      </v-form>
    </template>
  </HeaderDialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "MathProblemResolver",

  props: {
    show: { type: Boolean, default: false },
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    canNotReload: { type: Boolean, default: false },
  },

  data() {
    return {
      mathProblem: "",
      mathResult: "",
      challengeAnswer: 0,
      checkChallengeRule: true,
      hasError: false,
    };
  },

  computed: {
    ...mapGetters(["primaryColor"]),

    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },

  watch: {
    async showDialog() {
      if (this.showDialog) {
        this.challengeAnswer = "";
        await this.getChallenge();
      }
    },
  },

  methods: {
    getChallenge() {
      const operators = {
        "+": (a, b) => a + b,
        "-": (a, b) => a - b,
      };
      const random = () => Math.floor(Math.random() * (100 - 1)) + 1;
      const randomOP = () => {
        let keys = Object.keys(operators);
        return keys[Math.round(Math.random())];
      };

      let num1 = random();
      let num2 = random();

      let operator = randomOP();

      this.mathResult = operators[operator](num1, num2);
      this.mathProblem = `${num1} ${operator} ${num2}`;
    },

    validate() {
      if (this.mathResult == this.challengeAnswer) {
        this.$emit("success");
        this.$emit("close");
      } else {
        this.hasError = true;
        this.$emit("error");
      }
    },
  },
};
</script>
<style>
.problem {
  background-size: 15px 15px;
  background-image: linear-gradient(
      90deg,
      rgb(219, 219, 219) 1px,
      transparent 0px
    ),
    linear-gradient(rgb(219, 219, 219) 1px, transparent 0px);
  background-repeat: repeat;
  background-position: center;
}
.challenge-button input {
  text-align: center !important;
}
</style>
