<template>
  <HeaderDialog
    subtitle="Aplicar desconto para este plano"
    :title="`Plano ${planName} - ${customerName}`"
    :show="showEditDiscount"
    width="600"
    :disabled-action="invalidDiscount"
    action-text="Aplicar desconto"
    button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
    @close="close"
    @action="applyDiscount"
  >
    <template v-slot:body>
      <p class="mb-5">
        Aplique um desconto ou valor promocional para o plano deste cliente.
      </p>

      <v-scroll-y-transition>
        <Alert
          v-if="showRemovedMaxDiscount"
          :text="false"
          dense
          dark
          color="success"
        >
          Limite de desconto máximo removido.
        </Alert>
      </v-scroll-y-transition>

      <v-row class="ma-0 pb-0">
        <v-col cols="1" sm="1" md="1" class="mb-0 pb-0">
          <div class="pr-4 pt-3">
            <template>
              <v-icon
                v-text="'ph-currency-dollar-simple'"
                size="2em"
                color="accent"
              />
            </template>
          </div>
          <div class="pt-12">
            <template>
              <v-icon v-text="'ph-percent'" size="2em" color="accent" />
            </template>
          </div>
        </v-col>
        <v-col cols="11" sm="11" md="11" class="mb-0 pb-0">
          <v-form style="width: 100%" ref="editPlanDiscount">
            <!-- PRICE -->
            <vuetify-money
              v-model="newPrice"
              label="Valor por licença/mês (R$)"
              :hint="`O valor do desconto não deve ultrapassar ${maxDiscount}%.`"
              :placeholder="'4,99'"
              :options="moneyOptions"
              outlined
              clearable
            ></vuetify-money>

            <!-- DISCOUNT -->
            <v-text-field
              v-model="updatedDiscount"
              label="Valor do desconto (%)"
              :hint="
                maxDiscount === 35
                  ? `O valor do desconto não deve ultrapassar ${maxDiscount}%.`
                  : ''
              "
              :max="maxDiscount"
              :success="showRemovedMaxDiscount"
              type="number"
              min="0"
              outlined
              clearable
              :rules="[minAndMaxDiscountRule]"
            />
            <v-btn
              small
              block
              color="accent"
              class="elevation-0 mb-3"
              v-if="newDiscount > maxDiscount && newDiscount <= 100"
              @click="showChallengeDiscount = true"
            >
              <v-icon v-text="'ph-x-circle'" left />
              Remover limite de desconto
            </v-btn>

            <MathProblemResolver
              :show="showChallengeDiscount"
              title="Remover limite de desconto"
              :description="`Resolva o problema abaixo para confirmar a <b>remoção do limite de desconto</b>.`"
              @success="validateProblem"
              @close="showChallengeDiscount = false"
            />
          </v-form>
        </v-col>
      </v-row>
      <v-card
        outlined
        class="ma-0 pt-0 mt-5 grey lighten-5 pa-4 pt-5 grey--text text--darken-2"
      >
        <div class="mb-2 text-body-2">Total a pagar</div>
        <div class="text--darken-2">
          <div
            :class="`ma-0 pa-0 text-h5 font-weight-medium ${
              updatedDiscount > maxDiscount || updatedDiscount < 0
                ? 'error--text text--lighten-1'
                : 'primary--text text--lighten-3'
            }`"
          >
            {{ priceByPeriodPreviewText }}

            <v-slide-y-reverse-transition>
              <v-chip
                color="success"
                class="font-weight-medium mb-1"
                small
                v-if="showDiscountText"
              >
                Desconto de {{ discountText }}% do valor original
              </v-chip>
            </v-slide-y-reverse-transition>
          </div>

          <div
            :class="`ma-0 pa-0 text-body-2 mt-1 font-weight-medium ${
              updatedDiscount > maxDiscount || updatedDiscount < 0
                ? 'error--text text--lighten-1'
                : ''
            }`"
          >
            {{ totalPricePreviewText }}
          </div>
        </div>
      </v-card>
    </template>
  </HeaderDialog>
</template>
<script>
import {
  formatMoney,
  hasDecimal,
  moneyOptions,
} from "@/helpers/services/utils";
import { SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";
import { translatePeriods } from "@/helpers/variables/translateString";

import {
  getPriceByPeriod,
  getTotalPlanPrice,
} from "@/helpers/services/billing";

import { mapMutations } from "vuex";

import MathProblemResolver from "@/components/general/MathProblemResolver";
export default {
  name: "DiscountDialog",

  props: {
    customerName: { type: String, default: "Cliente" },
    discount: { type: [Number, String], default: 0 },
    licenses: { type: Number, default: 0 },
    maxLicenses: { type: Number, default: 0 },
    planPrice: { type: Number, default: 0 },
    planName: { type: String, default: "Security" },
    show: { type: Boolean, required: true, default: false },
    subscriptionType: { type: String, default: SUBSCRIPTIONS.FLEX },
  },

  components: { MathProblemResolver },

  data() {
    return {
      maxDiscount: 35,
      moneyOptions,
      minAndMaxDiscountRule: (value) =>
        (!!value &&
          parseFloat(value) >= 0 &&
          parseFloat(value) <= this.maxDiscount) ||
        `Por favor, insira uma porcentagem válida entre 0% e ${this.maxDiscount}%`,
      newDiscount: 0,
      newPrice: 0,
      showChallengeDiscount: false,
      showRemovedMaxDiscount: false,
      SUBSCRIPTIONS,
      timeout: null,
      translatePeriods,
    };
  },

  computed: {
    chargedLicenses() {
      return this.maxLicenses > this.licenses
        ? this.maxLicenses
        : this.licenses;
    },

    discountText() {
      let discount = parseFloat(this.newDiscount) || 0;

      if (discount > 0) {
        if (hasDecimal(discount)) {
          return `~${discount.toFixed(2)}`;
        }
        return `${parseInt(discount)}`;
      }
      return "";
    },

    formatedDiscount() {
      if (this.newDiscount > 1) {
        return this.newDiscount / 100;
      }
      return this.newDiscount;
    },

    invalidDiscount() {
      if (this.newDiscount == "") {
        return true;
      } else if (
        this.newDiscount >= 0 &&
        this.newDiscount <= this.maxDiscount
      ) {
        return false;
      }
      return true;
    },

    previewDiscount() {
      if (this.newDiscount > 1) {
        return this.newDiscount;
      }
      return this.newDiscount * 100;
    },

    /**
     * Price calculated based on discount
     * @return {Number}
     */
    priceByUserAndPeriod() {
      return formatMoney(
        getPriceByPeriod(
          this.subscriptionType,
          this.planPrice,
          this.formatedDiscount
        )
      );
    },

    priceByPeriodPreviewText() {
      const period = translatePeriods[this.subscriptionType];
      return `${this.priceByUserAndPeriod}/usuário/${period}`;
    },

    totalPricePreviewText() {
      const period = translatePeriods[this.subscriptionType];
      return `(${this.totalPrice}/${period} para ${this.chargedLicenses} ${
        this.chargedLicenses === 1 ? "usuário" : "usuários"
      })`;
    },

    /**
     * The final total price of the plan
     * @return {Number}
     */
    totalPrice() {
      return formatMoney(
        getTotalPlanPrice(
          this.chargedLicenses,
          this.subscriptionType,
          this.formatedDiscount,
          this.planPrice
        )
      );
    },

    showDiscountText() {
      return (
        this.newDiscount > 0 &&
        this.planPrice &&
        this.discountText &&
        this.subscriptionType !== SUBSCRIPTIONS.TRIAL
      );
    },

    showEditDiscount: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    updatedDiscount: {
      get() {
        return this.newDiscount;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.newDiscount = val;
          this.calculateDiscount(val);
        }, 800);
      },
    },
  },

  watch: {
    maxDiscount() {
      if (this.maxDiscount === 100) {
        this.showRemovedMaxDiscount = true;
        setTimeout(() => {
          this.showRemovedMaxDiscount = false;
        }, 5000);
      }
    },

    newDiscount() {
      if (this.newDiscount) {
        this.newDiscount = parseFloat(this.newDiscount).toFixed(2);
      }
    },

    newPrice() {
      if (this.showEditDiscount) {
        setTimeout(this.setDiscount, 2000);
      } else {
        this.setDiscount();
      }
    },

    showEditDiscount() {
      if (this.showEditDiscount) {
        this.$nextTick(() => {
          if (this.$refs.editPlanDiscount) {
            this.$refs.editPlanDiscount.resetValidation();
          }
        });
        this.reset();
      }
    },
  },

  methods: {
    hasDecimal,
    formatMoney,

    ...mapMutations(["updatePlanInCart"]),

    applyDiscount() {
      this.$emit("update", {
        discount: this.formatedDiscount,
        price: this.newPrice,
      });
      this.showEditDiscount = false;
    },

    calculateDiscount(val) {
      this.newPrice = this.planPrice * (1 - val / 100);
    },

    close() {
      this.$emit("close");
      this.showChallengeDiscount = false;
      this.reset();
    },

    reset() {
      const discount =
        parseFloat(this.discount) > 1
          ? parseFloat(this.discount)
          : parseFloat(this.discount) * 100;

      this.newDiscount = discount;
      this.newPrice = this.planPrice * (1 - this.formatedDiscount);
    },

    setDiscount() {
      const discount = parseFloat(
        ((this.planPrice - this.newPrice) * 100) / this.planPrice
      );

      this.newDiscount = discount;

      this.updatePlanInCart({
        key: "discount",
        value: this.newDiscount > 1 ? this.newDiscount / 100 : this.newDiscount,
      });
    },

    validateProblem() {
      this.maxDiscount = 100;
      this.newDiscount = Number(this.newDiscount); // force re-render
    },
  },

  beforeMount() {
    this.reset();
  },
};
</script>
