<template>
  <v-card
    outlined
    hover
    :class="`${styles} mb-4 product-card pa-0 d-flex flex-column justify-space-between overflow-hidden`"
    height="250"
    width="100%"
    :disabled="disabled"
  >
    <div class="justify-start my-0">
      <v-chip
        v-if="featured && $vuetify.breakpoint.smAndDown"
        class="font-weight-medium d-flex align-center"
        small
        style="border-radius: 0% !important"
        label
        color="amber lighten-2"
      >
        <v-icon v-text="'ph-star'" class="px-0 mr-1" size="16" />
        Melhor opção
      </v-chip>
      <v-row class="ma-0 px-0 py-3">
        <v-col
          :cols="hasSpecialTag ? 7 : 12"
          class="d-flex flex-wrap align-start pa-0 ma-0 pl-3"
        >
          <span
            v-for="product in productsNames"
            :key="product"
            style="width: 40px"
            class="mr-1"
          >
            <Logo height="35" :product="product" noMargins products />
          </span>
        </v-col>
        <v-col
          v-if="hasSpecialTag"
          :cols="5"
          class="pa-0 ma-0 d-flex align-center"
        >
          <v-chip
            v-if="featured && $vuetify.breakpoint.mdAndUp"
            class="ml-auto font-weight-medium offer-chip d-flex align-center mb-2 mr-6"
            small
            color="amber lighten-2"
          >
            <v-icon v-text="'ph-star'" class="px-0 mr-1" size="16" />
            Melhor opção
          </v-chip>

          <v-chip
            v-if="bestOffer"
            class="ml-auto font-weight-medium offer-chip d-flex align-center mb-2"
            small
            color="orange lighten-1"
          >
            <v-icon v-text="'mdi-lightning-bolt'" class="px-0 mr-1" size="16" />
            Melhor oferta
          </v-chip>
        </v-col>
      </v-row>

      <v-card-text class="text-center px-3 py-0 my-0">
        <div class="d-flex align-center">
          <div class="d-flex flex-column mx-0 px-0">
            <h4 class="text-left">
              {{ formatModuleName(title) }}
            </h4>

            <p
              v-if="description"
              class="text-left grey--text text--darken-1 mt-2"
            >
              {{ description }}
            </p>

            <v-btn
              v-else
              x-small
              text
              target="_blank"
              href="https://conectasuite.com/precos/"
              class="caption text-none mb-2 pa-0 mr-auto font-weight-medium mt-1"
              color="accent"
            >
              Saiba mais
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </div>
    <div class="text-center justify-end">
      <div class="d-flex align-center justify-end mb-4">
        <v-btn
          data-testid="select-new-plan-button"
          v-if="isNewPlan && canEditThisPlan"
          rounded
          right
          small
          class="text-none text-subtitle-2 py-3 px-4 elevation-0 mr-4"
          :color="`primary ${isSelected ? 'lighten-2' : ''}`"
          @click.native="$emit('selected', planKey)"
          :disabled="!canEditThisPlan"
        >
          {{ selectButtonText }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import Logo from "@/components/base/Logo";
import { formatModuleName } from "@/helpers/services/utils";
export default {
  components: { Logo },
  name: "CardReseller",
  props: {
    companyPlan: { type: Boolean, default: false },
    newService: { type: Boolean, default: false },
    isNewPlan: { type: Boolean, default: false },
    logo: { type: String, default: "" },
    title: { type: String, required: true },
    styles: { type: String, default: "" },
    products: { type: Array, default: () => [] },
    planKey: { type: String, default: "" },
    description: { type: String, default: "" },
    endTrial: { type: String, default: "" },
    featured: { type: Boolean, default: false },
    bestOffer: { type: Boolean, default: false },
    canEditThisPlan: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false },
  },
  data() {
    return {
      statusIcon: {
        active: "ph-check-circle",
        trial: "ph-warning-circle",
        disabled: "ph-x-circle",
      },
      colorIcon: {
        active: "success",
        trial: "warning",
        disabled: "disabled",
      },
    };
  },

  computed: {
    selectButtonText() {
      return this.isSelected ? "Selecionado" : "Selecionar";
    },

    hasSpecialTag() {
      return this.featured || this.bestOffer;
    },

    // Irá retornar o nome dos produtos deixando sempre na frente o Conecta Suite
    productsNames() {
      return this.products
        ? this.products
            .map((product) => product.name)
            .sort(function (a, b) {
              if (a == "conecta_suite") {
                return -1;
              }
              if (b == "conecta_suite") {
                return 1;
              }
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            })
        : [];
    },
  },

  methods: {
    formatModuleName,
  },
};
</script>
<style scoped>
.black-and-white {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; /* IE */
}
.module-card {
  cursor: pointer;
}
.product-card {
  cursor: auto !important;
  border-radius: 8px !important;
}
.new-service-icon {
  background: -moz-linear-gradient(top, #311f50 0%, #957ebd 100%) !important;
  background: -webkit-linear-gradient(top, #311f50 0%, #957ebd 100%) !important;
  background: linear-gradient(to bottom, #311f50 0%, #957ebd 100%) !important;
  -webkit-background-clip: text !important;
  -moz-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.new-service-title {
  font-size: 14px !important;
  margin: -10px 0 0 0 !important;
}
.select-product {
  text-transform: capitalize !important;
  font-weight: normal !important;
}
.trialend {
  font-size: 11.8px !important;
  line-height: 15px !important;
}
.offer-chip {
  border-radius: 7px 0px 0px 7px !important;
  margin-right: -6px !important;
  padding: 10px 12px 10px 8px !important;
}
</style>
