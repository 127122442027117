var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""},on:{"click":_vm.openShowCompanyProfileFormDialog}},[_c('v-row',{staticClass:"ma-0 py-0 px-1",attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0"},[_c('v-card-title',{staticClass:"px-1 font-weight-medium text-body-1 text-wrap"},[_vm._v(" Informações da empresa "),(!_vm.cnpj)?_c('v-tooltip',{attrs:{"top":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"pulse ml-1",attrs:{"size":"25","color":"error"},domProps:{"textContent":_vm._s('ph-fill ph-warning-circle')}},'v-icon',attrs,false),on))]}}],null,false,2204564969)},[_c('span',[_vm._v("Conclua o cadastro da empresa para habilitar o botão "),_c('b',[_vm._v("Confirmar pedido")])])]):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-list-item',{staticClass:"px-0",attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('v-row',{staticClass:"ma-0 py-2 px-2",attrs:{"no-gutters":""}},_vm._l((_vm.companyInfoSummary),function(item,i){return _c('v-col',{key:i,class:`py-0 px-4 ${i === 1 ? _vm.customAlignCorporateName : ''} ${
                i === 2 ? _vm.customAlignResponsibleEmail : ''
              } ${_vm.$vuetify.breakpoint.mdAndDown ? 'py-2' : ''}`,attrs:{"cols":"12","md":"12","lg":"4"}},[_c('div',{class:`px-0 font-weight-medium text-body-2 text-wrap ${
                  !item.value ? 'error--text' : ''
                }`,domProps:{"textContent":_vm._s(item.title)}}),_c('div',{class:`px-0 text-body-2 text-wrap ${
                  !item.value ? 'error--text' : ''
                }`},[_vm._v(" "+_vm._s(item.value ? item.value : "Não cadastrado")+" ")])])}),1)],1)],1),_c('v-list-item-action',{staticClass:"pr-2"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("ph-pencil-simple")])],1)],1)],1),_c('AdvicePermissionDialog',{attrs:{"show":_vm.openAdvice,"title":"Sem permissão de acesso","message":"Você não tem permissão para acessar o Perfil da Empresa. Peça\n      para um administrador concluir o cadastro desta empresa antes de fechar\n      um plano."},on:{"close":function($event){_vm.openAdvice = false}}}),_c('HeaderDialog',{attrs:{"title":_vm.companyName,"subtitle":"Informações da Empresa","show":_vm.editCompanyInfoDialog,"read-only":"","width":"800"},on:{"close":function($event){_vm.editCompanyInfoDialog = false}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('CompanyProfileForm',{attrs:{"isSelfCheckout":true,"companyProfile":_vm.selectedResellerClient},on:{"close":function($event){_vm.editCompanyInfoDialog = false}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }