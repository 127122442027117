<template>
  <div>
    <v-card outlined class="mb-4" @click="openShowCompanyProfileFormDialog">
      <v-row align="center" class="ma-0 py-0 px-1">
        <v-col class="py-0">
          <v-card-title class="px-1 font-weight-medium text-body-1 text-wrap">
            Informações da empresa
            <v-tooltip v-if="!cnpj" top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="pulse ml-1"
                  v-text="'ph-fill ph-warning-circle'"
                  size="25"
                  color="error"
                  v-on="on"
                  v-bind="attrs"
                >
                </v-icon>
              </template>
              <span
                >Conclua o cadastro da empresa para habilitar o botão
                <b>Confirmar pedido</b></span
              >
            </v-tooltip>
          </v-card-title>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-list-item link class="px-0">
        <v-list-item-content>
          <v-list-item-subtitle>
            <v-row class="ma-0 py-2 px-2" no-gutters>
              <v-col
                v-for="(item, i) in companyInfoSummary"
                :key="i"
                :class="`py-0 px-4 ${i === 1 ? customAlignCorporateName : ''} ${
                  i === 2 ? customAlignResponsibleEmail : ''
                } ${$vuetify.breakpoint.mdAndDown ? 'py-2' : ''}`"
                cols="12"
                md="12"
                lg="4"
              >
                <div
                  :class="`px-0 font-weight-medium text-body-2 text-wrap ${
                    !item.value ? 'error--text' : ''
                  }`"
                  v-text="item.title"
                ></div>
                <div
                  :class="`px-0 text-body-2 text-wrap ${
                    !item.value ? 'error--text' : ''
                  }`"
                >
                  {{ item.value ? item.value : "Não cadastrado" }}
                </div>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="pr-2">
          <v-icon left>ph-pencil-simple</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-card>

    <AdvicePermissionDialog
      :show="openAdvice"
      @close="openAdvice = false"
      title="Sem permissão de acesso"
      message="Você não tem permissão para acessar o Perfil da Empresa. Peça
        para um administrador concluir o cadastro desta empresa antes de fechar
        um plano."
    />

    <!-- DIALOG DE EDIÇÃO DAS INFORMAÇÕES DA EMPRESA -->
    <HeaderDialog
      :title="companyName"
      subtitle="Informações da Empresa"
      :show="editCompanyInfoDialog"
      @close="editCompanyInfoDialog = false"
      read-only
      width="800"
    >
      <template v-slot:body>
        <CompanyProfileForm
          :isSelfCheckout="true"
          :companyProfile="selectedResellerClient"
          @close="editCompanyInfoDialog = false"
        />
      </template>
    </HeaderDialog>
  </div>
</template>
<script>
import CompanyProfileForm from "@/components/billing/CompanyProfileForm";
import AdvicePermissionDialog from "@/components/home/dialogs/AdvicePermissionDialog.vue";

import { maskCNPJ } from "@/helpers/services/utils";
import { mapGetters } from "vuex";

export default {
  name: "CompanyInfo",

  components: {
    AdvicePermissionDialog,
    CompanyProfileForm,
  },

  data() {
    return {
      openAdvice: false,
      editCompanyInfoDialog: false,
    };
  },

  computed: {
    ...mapGetters(["hasProfilePermission", "selectedResellerClient"]),

    companyName() {
      return this.selectedResellerClient
        ? this.selectedResellerClient.name
        : "";
    },

    cnpj() {
      return maskCNPJ(this.selectedResellerClient?.cnpj);
    },

    corporateName() {
      return this.selectedResellerClient?.corporate_name;
    },

    financialEmail() {
      return this.selectedResellerClient?.financial_email || "Não cadastrado";
    },

    companyInfoSummary() {
      return [
        {
          title: "CNPJ",
          value: this.cnpj,
        },
        {
          title: "Razão social",
          value: this.corporateName,
        },
        {
          title: "E-mail do financeiro",
          value: this.financialEmail,
        },
      ];
    },

    customAlignCorporateName() {
      return this.$vuetify.breakpoint.lgAndUp ? "pl-8" : "";
    },

    customAlignResponsibleEmail() {
      return this.$vuetify.breakpoint.lgAndUp ? "pl-12 pr-0" : "";
    },
  },

  methods: {
    maskCNPJ,

    openShowCompanyProfileFormDialog() {
      if (this.hasProfilePermission) {
        this.editCompanyInfoDialog = true;
      } else {
        this.openAdvice = true;
      }
    },
  },
};
</script>
